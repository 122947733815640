import React from "react";

function Studio() {
  return (
    <section
      id="studio"
      className="py-16 px-8"
      style={{ backgroundColor: "rgb(1, 35, 38)" }}
    >
      <h2 className="text-3xl font-bold text-center mb-8" style={{ color: "#BFAE99" }}>
        O Estúdio
      </h2>
      <div className="space-y-6 max-w-4xl mx-auto text-center text-white">
        {/* Descrição */}
        <p>
          “AJUDAR O PRÓXIMO” – Este é o nosso lema. Criámos um espaço onde se sentirá melhor do que quando entrou pela primeira vez.
        </p>
        <p>
          Num ambiente acolhedor e familiar, o treino é feito à sua medida. Sinta-se em casa enquanto cuida da sua saúde.
        </p>

        {/* Morada */}
        <div className="mt-8">
          <p className="font-bold">Localização:</p>
          <p>Rua Abel Salazar, número 2B, Setúbal 2910-372</p>
        </div>

        {/* Google Maps */}
        <div className="mt-8">
          <iframe
            title="Localização"
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3162.6519110171365!2d-8.8945691!3d38.5247331!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0xd18a5fdb5f32d35%3A0x394edfc3b7a2d679!2sRua%20Abel%20Salazar%2C%202B%2C%202910-372%20Set%C3%BAbal!5e0!3m2!1spt-PT!2spt!4v1670847574632!5m2!1spt-PT!2spt"
            className="w-full h-80 rounded-lg shadow-md"
            allowFullScreen=""
            loading="lazy"
          ></iframe>
        </div>
      </div>
    </section>
  );
}

export default Studio;

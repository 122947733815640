import React from "react";
import { FaDumbbell, FaRunning, FaLaptop, FaHome, FaClipboardList, FaChartLine } from "react-icons/fa"; // Ícones para os serviços

function Services() {
  const services = [
    {
      title: "Personal Training",
      description: "Treine com acompanhamento personalizado para obter resultados rápidos.",
      icon: <FaDumbbell className="text-4xl text-[#BFAE99]" />, // Ícone personalizado
    },
    {
      title: "Performance Desportiva",
      description: "Melhore a sua performance com treinos específicos para atletas.",
      icon: <FaRunning className="text-4xl text-[#BFAE99]" />,
    },
    {
      title: "Treino Online",
      description: "Treine onde quiser com os nossos treinos online personalizados.",
      icon: <FaLaptop className="text-4xl text-[#BFAE99]" />,
    },
    {
      title: "Treino ao Domicílio",
      description: "Prefere treinar no conforto de casa? Nós vamos até si.",
      icon: <FaHome className="text-4xl text-[#BFAE99]" />,
    },
    {
      title: "Planos de Treino Ajustados",
      description: "Treinos adaptados ao seu corpo e estilo de vida.",
      icon: <FaClipboardList className="text-4xl text-[#BFAE99]" />,
    },
    {
      title: "Avaliações Físicas Regulares",
      description: "Acompanhamento contínuo para garantir sua evolução.",
      icon: <FaChartLine className="text-4xl text-[#BFAE99]" />,
    },
  ];

  return (
    <section
      id="services"
      className="py-16 px-8"
      style={{ backgroundColor: "rgb(1, 35, 38)" }}
    >
      <h2 className="text-3xl font-bold text-center mb-8" style={{ color: "#BFAE99" }}>
        Serviços
      </h2>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8 max-w-6xl mx-auto">
        {services.map((service, index) => (
          <div
            key={index}
            className="bg-[#012326] text-white p-6 shadow-lg rounded-lg flex flex-col items-center justify-center text-center border border-[#BFAE99] hover:scale-105 transition-transform duration-300"
          >
            {/* Ícone */}
            <div className="mb-4">{service.icon}</div>
            {/* Título */}
            <h3 className="text-xl font-bold mb-2">{service.title}</h3>
            {/* Descrição */}
            <p>{service.description}</p>
          </div>
        ))}
      </div>
    </section>
  );
}

export default Services;

import React from "react";
import Header from "./components/Header";
import Slider from "./components/Slider";
import About from "./components/About";
import Services from "./components/Services";
import Studio from "./components/Studio";
import Contact from "./components/Contact";
import Footer from "./components/Footer";

function App() {
  return (
    <div className="w-full min-h-screen bg-[#f9f4ee]">
      {/* Header */}
      <Header />

      {/* Slogan */}
      <div className="py-8 text-center">
        <h1
          style={{ color: "#012326",fontFamily: "Times New Roman, serif" }}
          className="text-3xl font-bold "
        >
          O que não nos desafia não nos transforma
        </h1>
      </div>

      {/* Slider */}
      <Slider />

      <About />
      <Services />
      <Studio />
      <Contact />
      <Footer />
    </div>
  );
}

export default App;

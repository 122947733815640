import React from "react";
import { FaInstagram, FaTiktok } from "react-icons/fa";

function Footer() {
  return (
    <footer
      className="h-20 flex justify-between items-center overflow-hidden px-8"
      style={{ backgroundColor: "#012326" }}
    >
      {/* Logotipo à Esquerda */}
      <img
        src="/images/logo.png"
        alt="MindStudio Logo"
        className="h-56 object-cover"
        style={{
          clipPath: "inset(10% 10% 10% 10%)", // Mesma configuração do header
        }}
      />

      {/* Ícones das Redes Sociais à Direita */}
      <div className="flex space-x-6">
        <a
          href="https://www.instagram.com/mindstudiosetubal_/"
          target="_blank"
          rel="noopener noreferrer"
          className="text-[#BFAE99] hover:text-white transition-colors duration-300"
          aria-label="Instagram"
        >
          <FaInstagram className="text-2xl" />
        </a>
        <a
          href="https://www.tiktok.com/@mindstudiosetubal"
          target="_blank"
          rel="noopener noreferrer"
          className="text-[#BFAE99] hover:text-white transition-colors duration-300"
          aria-label="TikTok"
        >
          <FaTiktok className="text-2xl" />
        </a>
      </div>
    </footer>
  );
}

export default Footer;

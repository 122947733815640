import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/effect-coverflow";
import { EffectCoverflow } from "swiper/modules";


function Slider() {
  const images = [
    "/images/slide1.jpg", // Caminho simples e direto
    "/images/slide2.jpg",
    "/images/slide3.jpg",
    "/images/slide4.jpg",
    "/images/slide5.jpg",
  ];
  
  

  return (
    <div className="w-full h-screen flex justify-center items-start overflow-hidden">
      <Swiper
        effect={"coverflow"}
        centeredSlides={true}
        loop={true} // Permite loop contínuo
        slidesPerView={1} // Mostra 1 slide no telemóvel
        spaceBetween={0} // Remove margens no mobile
        breakpoints={{
          640: {
            slidesPerView: 3, // Mostra 3 slides no desktop
            spaceBetween: 70, // Espaçamento horizontal maior entre fotos
          },
        }}
        coverflowEffect={{
          rotate: 0,
          stretch: 0,
          depth: 100,
          modifier: 2,
          slideShadows: false,
        }}
        modules={[EffectCoverflow]}
        className="w-full h-full"
      >
        {images.map((img, index) => (
          <SwiperSlide
            key={index}
            className="flex justify-center items-center h-full"
          >
            <img
              src={img}
              alt={`Slide ${index + 1}`}
              className="w-auto object-contain"
            />
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
}

export default Slider;

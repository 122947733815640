import React from "react";

function Contact() {
  return (
    <section id="contact" className="py-16 px-8">
      {/* Título */}
      <h2 className="text-3xl font-bold text-center mb-8 text-gray-800">
        Contacto
      </h2>

      {/* Texto Adicional */}
      <div className="max-w-4xl mx-auto text-center text-gray-700 space-y-4 mb-8">
        <p className="text-xl font-bold">VEM CONHECER-NOS</p>
        <p>
          Pretende realizar uma aula experimental? Preencha o formulário com os seus dados para que possamos entrar em contacto consigo.
        </p>
        <p className="font-bold">Encontramo-nos no MIND!</p>
      </div>

      {/* Formulário */}
      <form className="max-w-4xl mx-auto space-y-4">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
          {/* Nome */}
          <input
            type="text"
            placeholder="Primeiro e Último Nome"
            className="border p-3 rounded w-full"
          />
          {/* Email */}
          <input
            type="email"
            placeholder="E-mail"
            className="border p-3 rounded w-full"
          />
          {/* Telemóvel */}
          <input
            type="text"
            placeholder="Telemóvel"
            className="border p-3 rounded w-full"
          />
          {/* Serviço Pretendido */}
          <select className="border p-3 rounded w-full">
            <option>Serviço Pretendido</option>
            <option>Personal Training</option>
            <option>Treino Online</option>
            <option>Treino ao Domicílio</option>
          </select>
        </div>
        {/* Botão de Enviar */}
        <button
          type="submit"
          className="bg-[#012326] text-white px-6 py-3 rounded shadow-md hover:bg-[#01474c] transition duration-300"
        >
          Enviar
        </button>
      </form>
    </section>
  );
}

export default Contact;

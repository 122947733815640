import React from "react";

function Header() {
  return (
    <header className=" h-20 flex justify-center items-center overflow-hidden"
    style={{ backgroundColor: "#012326" }}
    >
     
        <img
          src="/images/logo.png"
          alt="MindStudio Logo"
          className="h-56 object-cover"
          style={{
            clipPath: "inset(10% 10% 10% 10%)", // Remove 5% de cada lado (ajustável)
          }}
        />
  
    </header>
  );
}

export default Header;

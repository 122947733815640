import React from "react";

function About() {
  return (
    <section id="about" className="py-16 px-8" style={{ backgroundColor: "#012326" }}>
      <h2 className="text-3xl font-bold text-center mb-8" style={{ color: "#BFAE99" }}>
        Sobre Nós
      </h2>
      <div className="flex flex-col md:flex-row items-stretch gap-8 max-w-6xl mx-auto">
        {/* Texto à Esquerda */}
        <div className="text-white flex items-center flex-1">
          <div className="space-y-6">
            <p>
              Nos dias de hoje, com a correria do quotidiano, pouca motivação sobra para ir ao ginásio. 
              No MIND, a falta de tempo deixa de ser desculpa! Pode treinar à hora que quiser, conciliando a sua rotina pessoal e profissional.
            </p>
            <p>
              No nosso estúdio, o ginásio estará disponível exclusivamente para si no momento reservado. 
              Criámos um espaço acolhedor e calmo com profissionais dinâmicos para ajudar no seu progresso.
            </p>
            <p>
              São inúmeras as razões para treinar no MIND: saúde, estética, lazer ou competição. 
              Mas o que nos faz voltar é a empatia, a companhia e a boa energia que tornam seus objetivos mais fáceis de alcançar.
            </p>
          </div>
        </div>

        {/* Imagem à Direita */}
        <div className="flex-1 flex items-center justify-center">
          <img
            src="/images/sobrenos.jpg" // Caminho atualizado da imagem
            alt="Espaço MIND"
            className="rounded-lg shadow-lg w-3/4 max-w-sm"
          />
        </div>
      </div>
    </section>
  );
}

export default About;
